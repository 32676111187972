import React, { useEffect, useState } from "react";
import {
  IonButton,
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonImg,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
  IonItem,
} from "@ionic/react";
import ExploreContainer from "../components/ExploreContainer";
import "./Home.css";
import { heartOutline } from "ionicons/icons";
import { Browser } from "@capacitor/browser";

import { Swiper } from "swiper";
// import { Swiper as SwiperComponent } from "swiper/react";

import EffectMaterial from "../slider/effect-material.esm.js";

import "swiper/css";

import "../slider/effect-material.min.css";
import "../slider/index.css";

import "../slider/effect-material.esm";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import {
  collection,
  increment,
  updateDoc,
  doc,
  addDoc,
} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDL8S4zwKX3PYuY8s0k7tOXi2L0O71jaII",
  authDomain: "yasmin-silva-of.firebaseapp.com",
  projectId: "yasmin-silva-of",
  storageBucket: "yasmin-silva-of.appspot.com",
  messagingSenderId: "648425949825",
  appId: "1:648425949825:web:d01c2cb83fdbe1c474db80",
  measurementId: "G-RN9GP0Y4R4",
};
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

async function openURL() {
  Browser.open({ url: "https://onlyfans.com/yassminsilva/c12" });
  // await Browser.open({ url: "https://onlyfans.com/yassminsilva/c12" });
  buttonClick();
}

console.log(1);

pageVisit();

async function pageVisit() {
  const totalPageVisistsRef = doc(db, "visits/totalPageVisits");
  const dailyPageVisistsRef = collection(db, "visits");
  const dateObj = new Date();
  const date = `${dateObj.getDate()}.${
    dateObj.getMonth() + 1
  }.${dateObj.getFullYear()}`;
  console.log(date);

  try {
    await updateDoc(totalPageVisistsRef, {
      counter: increment(1),
    });

    await addDoc(dailyPageVisistsRef, {
      counter: 1,
      date,
    });
    console.log("Document written");
  } catch (e) {
    console.error("Error adding document: ", e);
  }
}

async function buttonClick() {
  const totalPageClicksRef = doc(db, "clicks/totalPageClicks");
  const dailyPageClickssRef = collection(db, "clicks");
  const dateObj = new Date();
  const date = `${dateObj.getDate()}.${
    dateObj.getMonth() + 1
  }.${dateObj.getFullYear()}`;
  console.log(date);

  try {
    await updateDoc(totalPageClicksRef, {
      counter: increment(1),
    });

    await addDoc(dailyPageClickssRef, {
      counter: 1,
      date,
    });
    console.log("Document written");
  } catch (e) {
    console.error("Error adding document: ", e);
  }
}

const Home: React.FC = () => {
  const [city, setCity] = useState("initial");

  const requestOptions = {
    method: "GET",
  };

  fetch(
    "https://api.geoapify.com/v1/ipinfo?&apiKey=d320baa562114e8ca9e619c907299510",
    requestOptions
  )
    .then((response) => response.json())
    .then((result) => setCity(result.city.name))
    .catch((error) => console.log("error", error));

  return (
    <IonPage
      onLoad={() => {
        const swiper = new Swiper(".swiper", {
          // pass EffectMaterial module to modules
          modules: [EffectMaterial],
          // specify "material" effect
          effect: "material",
          grabCursor: true,
          slidesPerView: 2,
          spaceBetween: 16,
          speed: 600,
          centeredSlides: true,
          initialSlide: 2,
          // autoplay: true,
        });
      }}
    >
      <IonHeader collapse="fade">
        <IonToolbar>
          <IonRow>
            <IonCol sizeMd="5" sizeSm="0"></IonCol>
            <IonCol sizeSm="12" sizeMd="2">
              <IonImg src="https://tinyblonde.top/images/onlyfanslogo.png"></IonImg>
            </IonCol>
            <IonCol sizeSm="0" sizeMd="5"></IonCol>
          </IonRow>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <div>
          <IonRow class="ion-text-center">
            <IonCol>
              <h1 style={{ marginBottom: "-1rem" }}>
                Do you live in <b style={{ color: "#1AA9E8" }}>{city}</b>?
              </h1>
              <h3>
                I am online & waiting for you 🟢 <br />
                80% OFF SALE FOR YOU 🎁
              </h3>
            </IonCol>
          </IonRow>

          <div className="demo-slider">
            <div className="swiper">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <div className="swiper-material-wrapper">
                    <div className="swiper-material-content">
                      <img
                        className="demo-material-image"
                        data-swiper-material-scale="1.25"
                        src="../../assets/images/yasmin-landingpage/1.jpg"
                      />
                      <span className="demo-material-label swiper-material-animate-opacity">
                        {/* You like my ass? */}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="swiper-material-wrapper">
                    <div className="swiper-material-content">
                      <img
                        className="demo-material-image"
                        data-swiper-material-scale="1.25"
                        src="../assets/images/yasmin-landingpage/2.jpg"
                      />
                      <span className="demo-material-label swiper-material-animate-opacity">
                        {/* Slide 2 */}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="swiper-material-wrapper">
                    <div className="swiper-material-content">
                      <img
                        className="demo-material-image"
                        data-swiper-material-scale="1.25"
                        src="../assets/images/yasmin-landingpage/3.jpg"
                      />
                      <span className="demo-material-label swiper-material-animate-opacity">
                        {/* Slide 3 */}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="swiper-material-wrapper">
                    <div className="swiper-material-content">
                      <img
                        className="demo-material-image"
                        data-swiper-material-scale="1.25"
                        src="../assets/images/yasmin-landingpage/4.jpg"
                      />
                      <span className="demo-material-label swiper-material-animate-opacity">
                        {/* Slide 4 */}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="swiper-material-wrapper">
                    <div className="swiper-material-content">
                      <img
                        className="demo-material-image"
                        data-swiper-material-scale="1.25"
                        src="../assets/images/yasmin-landingpage/5.jpg"
                      />
                      <span className="demo-material-label swiper-material-animate-opacity">
                        {/* Slide 4 */}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <IonRow class="ion-text-center" style={{ marginBottom: "-1rem" }}>
            <IonCol>
              <h6>
                Get <b> exclusive accesss </b> to my content <br />
                Interact with me directly 💦 <br />
                <b>DM me on OF after subscribing :)</b>
                <br />
                So we can make content🔥
              </h6>

              {/* <IonIcon icon={chatbubblesOutline}></IonIcon> */}
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol size="1" sizeMd="4"></IonCol>
            <IonCol size="10" sizeMd="4">
              <IonButton
                color="primary"
                expand="full"
                shape="round"
                onClick={() => openURL()}
              >
                SEE MY ONLYFANS
                <IonIcon slot="end" icon={heartOutline}></IonIcon>
              </IonButton>
            </IonCol>
            <IonCol size="1" sizeMd="4"></IonCol>
          </IonRow>
        </div>{" "}
      </IonContent>
    </IonPage>
  );
};

export default Home;
